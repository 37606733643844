.scrollup {
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  background-color: var(--title-color);
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.scrollup__icon {
  font-size: 1.5rem;
  color: var(--container-color);
}
.scrollup:hover {
  background-color: var(--title-color-dark);
}
.show__scroll {
  bottom: 3rem !important;
}
